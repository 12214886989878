<div class="main">
    <div class="linaf-filler"></div>
    <div class="text-holder">
        <div>
            <h2>Our partners</h2>
            <div class="splash">
                <img alt="logo" src="assets/partners/Tarento_logo.png">
            </div>
            <div class="splash">
                <img alt="logo" src="assets/partners/AKIRU-STUDIOS_logo-horiz-black_RGB.png">
            <div>
        </div>
    </div>
    <div class="linaf-filler"></div>
</div>
    </div>
</div>