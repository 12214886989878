<mat-toolbar>
  <div [routerLink]="['/']">
    <img [height]="70" style="padding-top: 14px" alt="logo" src="assets/logo/Linaf_logo_white_RGB.png">
  </div>
  <div class="linaf-filler"></div>
  <button [matMenuTriggerFor]="menu" mat-button>
    <mat-icon style="font-size: 24px" class="material-symbols-outlined">menu</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
      <button [routerLink]="['/']" mat-menu-item>
          Home
      </button>
<!--    <button [routerLink]="['about']" mat-menu-item>-->
<!--      About-->
<!--    </button>-->
    <button [routerLink]="['contact']" mat-menu-item>
      Contact Us
    </button>
    <button [routerLink]="['partners']" mat-menu-item>
      Partners
    </button>
    <button [routerLink]="['customers']" mat-menu-item>
      Customers
    </button>
  </mat-menu>
</mat-toolbar>
