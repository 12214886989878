import {Route, RouterModule} from '@angular/router'
import {NgModule} from '@angular/core'
import {SplashComponent} from './pages/0-splash/splash.component'
import {AboutComponent} from './pages/1-about/about.component'
import {ContactComponent} from './pages/2-contact/contact.component'
import {PartnersComponent} from './pages/3-partners/partners.component'
import {CustomersComponent} from './pages/4-customers/customers.component'

export const routes: Route[] = [
  {
    path: '',
    component: SplashComponent
  },
  {
    path: 'about',
    component: AboutComponent
  },
  {
    path: 'partners',
    component: PartnersComponent
  },
  {
    path: 'customers',
    component: CustomersComponent
  },
  {
    path: 'contact',
    component: ContactComponent
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    enableTracing: false,
    useHash: true,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'disabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
