import {Component} from '@angular/core'

@Component({
  selector: 'lif-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent {

}
