import {Component} from '@angular/core'

@Component({
  selector: 'lif-splash',
  templateUrl: './splash.component.html',
  styleUrls: ['./splash.component.scss']
})
export class SplashComponent {

}
