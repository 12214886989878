import {Component} from '@angular/core'

@Component({
  selector: 'lif-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  protected readonly top = top
}
