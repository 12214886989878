import {Component} from '@angular/core'

@Component({
  selector: 'lif-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent {

}
