<div class="main">
    <div class="linaf-filler"></div>
    <div class="text-holder">
        <div>
            <h2>Our customers</h2>
            <div class="splash">
                <img alt="logo" src="assets/customers/2344.jpg">
            </div>
            <div class="splash">
                <img alt="logo" src="assets/customers/sparbanken2.png">
            </div>
            <div class="splash">
                <img alt="logo" src="assets/customers/765.png">
            </div>
            <div class="splash">
                <img alt="logo" src="assets/customers/oresundskraft_rgb%20(1).png">
            </div>
            <div class="splash">
                <img alt="logo" src="assets/customers/1234.png">
            </div>
            <div class="splash">
                <img alt="logo" src="assets/customers/321.png">
            </div>
            <div class="splash">
                <img alt="logo" src="assets/customers/3456.png">
            </div>
            <div class="splash">
                <img alt="logo" src="assets/customers/region_blekinge_logo.png">
            </div>
            <div class="splash">
                <img alt="logo" src="assets/customers/678.svg">
            </div>
            <div class="splash">
                <img alt="logo" src="assets/customers/432.svg">
            </div>
            <div class="linaf-filler"></div>
        </div>
    </div>
</div>
