<div class="holder">
    <div class="linaf-filler"></div>
    <div class="title" id="about-us">
        About us
    </div>
    <div class="text-holder">
        <div>
            <h3>
                ”You have our support through a complete digital transformation within your company.
            </h3>
            <h3>
                We analyze, plan and execute the transformation according to the latest methodology at hand.”
            </h3>
        </div>
    </div>
    <div class="linaf-filler"></div>
</div>
