import {NgModule} from '@angular/core'
import {BrowserModule} from '@angular/platform-browser'
import {AppComponent} from './app.component'
import {AppRoutingModule} from './app-routing.module'
import {CommonModule} from '@angular/common'
import {HeaderComponent} from './common/header/header.component'
import {FooterComponent} from './common/footer/footer.component'
import {SplashComponent} from './pages/0-splash/splash.component'
import {AboutComponent} from './pages/1-about/about.component'
import {ContactComponent} from './pages/2-contact/contact.component'
import {MatToolbarModule} from '@angular/material/toolbar'
import {MatMenuModule} from '@angular/material/menu'
import {MatIconModule} from '@angular/material/icon'
import {MatButtonModule} from '@angular/material/button'
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { PartnersComponent } from './pages/3-partners/partners.component';
import { CustomersComponent } from './pages/4-customers/customers.component'


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    SplashComponent,
    AboutComponent,
    ContactComponent,
    PartnersComponent,
    CustomersComponent
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
