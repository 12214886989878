import { Component } from '@angular/core';

@Component({
  selector: 'lif-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss']
})
export class CustomersComponent {

}
