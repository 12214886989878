<div class="main">
    <div class="linaf-filler"></div>
    <div class="text-holder">
        <div>
            <h3>
                ”You have our support through a complete digital transformation within your company.
            </h3>
            <h3>
                We analyze, plan and execute the transformation according to the latest methodology and technology at hand.”
            </h3>
            <h4>
                Get in touch <a href="mailto:hej@linaf.se">hej@linaf.se</a>
            </h4>
        </div>
    </div>
    <div class="linaf-filler"></div>
</div>
